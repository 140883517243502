// import 'bootstrap/js/src/modal';
// import 'bootstrap/js/src/dropdown';
import './components/slideshow/slickslider';
import './index.scss';

import './parallax';
import './facebook';
// import './cookiebanner';

import './utils/SelectTabs';
import './utils/LazyLoad';
import './utils/analytics';

import 'ionicons/dist/css/ionicons.min.css';
import './menu';

import ReactDOM from 'react-dom';
import React from 'react';

import ModalsApp from './modals/ModalsApp';
import SelectedEventApp from './selected-event-app/SelectedEventApp';

const modalApp = document.getElementById('modalsReactApp');
if (modalApp) {
  ReactDOM.render(<ModalsApp />, modalApp);
}
const selectedEventApp = document.getElementById('selectedEventApp');
if (selectedEventApp) {
  ReactDOM.render(<SelectedEventApp />, selectedEventApp);
}
