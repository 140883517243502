export const $ = window.jQuery;

$(function() {
  document.addEventListener(
    'wpcf7mailsent',
    () => {
      if (typeof gtag === 'function') {
        gtag('event', 'submit_booking');
      }
    },
    false,
  );

  $('.contact-section-divider h3 a.text-white').on('click', e => {
    if (typeof gtag === 'function') {
      gtag('event', 'click_contact');
    }
  });
});
