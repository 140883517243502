import 'animate.css';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;
const navHeight = 100;
const $ = window.jQuery;
/* Every time the window is scrolled ... */
function shouldReveal() {
  $('.reveal:not(.show)').each(function() {
    // const height = $(this).outerHeight();
    const top_of_object = $(this).position().top + 100;
    const bottom_of_window = $(window).scrollTop() + windowHeight;

    /* If the object is completely visible in the window, fade it it */
    if (bottom_of_window > top_of_object) {
      const animation = this.dataset.animation || 'fadeIn';
      $(this).addClass(`animated show ${animation}`);
    }
  });
}

const header = document.getElementById('header');
const scrollDownArrow = document.querySelector('.scrollDownArrow');
const isFrontPage = document.querySelector('.front-page');
if (isFrontPage) {
  header.classList.remove('fill');
}

if ($(window).scrollTop() < 300) {
  if (scrollDownArrow) {
    scrollDownArrow.classList.remove('opacity-0');
  }
}

const onscroll = () => {
  shouldReveal();
  if (!isFrontPage) {
    return;
  }
  if ($(window).scrollTop() > 95) {
    header.classList.add('fill');
  } else {
    header.classList.remove('fill');
  }

  if ($(window).scrollTop() > 300) {
    if (scrollDownArrow) {
      scrollDownArrow.classList.add('opacity-0');
    }
  }
  // activateAnchorLinks();
  // doParallax();
};
$(window).scroll(() => {
  window.requestAnimationFrame(onscroll);
});
onscroll();

export class Calc {
  static map(n, start1, stop1, start2, stop2, withinBounds) {
    const newval =
      ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
    if (!withinBounds) {
      return newval;
    }
    if (start2 < stop2) {
      return this.constrain(newval, start2, stop2);
    }
    return this.constrain(newval, stop2, start2);
  }

  static constrain(n, low, high) {
    return Math.max(Math.min(n, high), low);
  }
}

function activateAnchorLinks() {
  $('.nav-list a[href*=#]').each(function() {
    const dest = $('#' + this.href.split('#')[1]);
    if (!dest.length) return;

    const destPos = dest.offset().top;

    const top_of_window = $(window).scrollTop();

    /* If the object is completely visible in the window, activate the current anchor link */
    if (top_of_window > destPos - 15) {
      $('.nav-list a[href*=#]').removeClass('active');
      $(this).addClass('active');
    }
  });
}
