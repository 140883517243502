import React from 'react';
import { hot } from 'react-hot-loader';
import { allEvents } from './allEvents';
import { EventItemStore } from './EventItemStore';
import { EventList } from './EventList';
import { SelectedEvent } from './SelectedEvent';
import { scrollToSelectedEvent } from './scrollToSelectedEvent';

const selectedEventApp = document.getElementById('selectedEventApp');

if (selectedEventApp) {
  function detectHashAndDisplayItem() {
    if (window.location.hash.includes('evenemang_')) {
      try {
        const eventSlug = decodeURI(window.location.hash).replace(
          '#evenemang_',
          '',
        );
        console.log('eventSlug', eventSlug);
        const selectedEvent = allEvents.find(
          e => String(e.eventSlug) === eventSlug,
        );
        if (selectedEvent) {
          EventItemStore.store.displayEventItem(selectedEvent);
          scrollToSelectedEvent();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  // Check on page load
  detectHashAndDisplayItem();

  window.addEventListener(
    'hashchange',
    () => {
      console.log('Hash changed');
      detectHashAndDisplayItem();
    },
    false,
  );
}

const SelectedEventApp = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-5">
          <EventList />
        </div>
        <div className="col-md-7 selected_event">
          <SelectedEvent />
        </div>
      </div>
    </div>
  );
};

export default hot(module)(SelectedEventApp);
