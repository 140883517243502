import { observable, action, computed } from 'mobx';
export class ModalStore {
  static _store: ModalStore;
  static get store() {
    if (this._store) {
      return this._store;
    }
    this._store = new ModalStore();
    window['modalStore'] = this._store;
    return this._store;
  }

  @observable displayingForm = false;
  @action setDisplayingForm(displayingForm) {
    this.displayingForm = displayingForm;
  }

  @observable displayingModal = false;
  @observable currentItem;

  @action displayModal(item) {
    this.displayingModal = true;
    this.currentItem = JSON.parse(item);
  }
  @computed get modalImages(): any[] {
    const modalImages = this.currentItem.imageGroup.modalImages || [];
    return [this.currentItem.imageGroup.image, ...modalImages];
  }
  @computed get modalContent(): {
    title: string;
    content: string;
    selectForm: string;
  } {
    return this.currentItem ? this.currentItem.buttonGroup.modalGroup : null;
  }
  @computed get contentGroup(): {
    title: string;
    content: string;
    cardSlug: string;
  } {
    return this.currentItem ? this.currentItem.contentGroup : null;
  }

  @action closeModal = () => {
    this.displayingModal = false;
    this.displayingForm = false;

    const output = document.querySelector('.wpcf7-response-output');
    if (output) {
      output.classList.add('d-none');
    }
  };
}
