const $ = window.jQuery;
export function scrollSmoothToBottom(selector) {
  var div = document.querySelector(selector);
  $(selector).animate(
    {
      scrollTop: div.scrollHeight - div.clientHeight,
    },
    300,
  );
}
