export const $ = window.jQuery;

$(document).ready(function() {
  let tabButtons = '.list-group>a';
  let tabContent = '.tab-content>div';
  let tabElements = $(tabButtons + ',' + tabContent);

  // switch tab function
  function switchTab(index) {
    tabElements.removeClass('show active'); // remove active class from current/all tabs and buttons
    $(tabContent)
      .eq(index)
      .addClass('show active'); // add active class to selected tab
  }

  // button click function
  $(tabButtons).click(function(e) {
    //e.preventDefault();
    let index = $(this).index(); // get index of clicked button
    switchTab(index); // call switch tab function
    $(this).addClass('active'); // add active class to clicked button
    e.preventDefault();
    e.stopPropagation();

    // We use pushState if it's available so the page won't jump, otherwise a shim.
    const hash = this.href.split('#')[1];
    if (history && history.pushState) {
      history.pushState(null, null, '#' + hash);
    } else {
      let scrollV = document.body.scrollTop;
      let scrollH = document.body.scrollLeft;
      window.location.hash = hash;
      document.body.scrollTop = scrollV;
      document.body.scrollLeft = scrollH;
    }
  });

  function hashTab() {
    // get URL Hash
    let hash = window.location.hash;
    // check if hash is set and not empty
    if (hash != '') {
      let hashLink = $(`.list-group a[href="${hash}"]`); // find the button that corresponds with the hash
      switchTab(hashLink.index()); // call switch tab function based on HASH index
      hashLink.addClass('active'); // add active class to tab button
    } else {
      switchTab(0);
      $(tabButtons)
        .first()
        .addClass('active');
    }
  }
  hashTab(); // fire hash tab function

  // detect hash change
  window.onhashchange = () => {
    // alert( location.hash ); // debug hash change
    hashTab(); // call tab switch function
  };

  // if (window.location.hash) {
  //   // do the test straight away
  //   window.scrollTo(0, 0) // execute it straight away
  //   setTimeout(function() {
  //     window.scrollTo(0, 0) // run it a bit later also for browser compatibility
  //   }, 1)
  // }

  // if (window.hashLink) {
  //   // history.pushState(null, null, '#' + window.hashLink)
  //   window.location.hash = window.hashLink;
  //   window.hashLink = null
  //   hashTab()

  //   setTimeout(function() {
  //     window.scrollTo(0, 0)
  //   }, 1)
  // }
});
