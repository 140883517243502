import compareAsc from 'date-fns/compareAsc';
import compareDesc from 'date-fns/compareDesc';

import format from 'date-fns/format';
// import formatLong from 'date-fns/formatLong';
// import parse from 'date-fns/parse'
import sv from 'date-fns/locale/sv';
import parseISO from 'date-fns/parseISO';
export { compareAsc, compareDesc, parseISO, format };
export const svLocale = sv;

export function formatDateString(date: string, formatStr: string) {
  const parsed = parseISO(date);
  return format(parsed, formatStr, {
    locale: sv,
  });
}
window['formatDate'] = formatDateString;

export function formatDate(date: Date, formatStr: string) {
  return format(date, formatStr, {
    locale: sv,
  });
}
export function toISOString(date: Date) {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx", {
    locale: sv,
  });
}
