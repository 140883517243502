import { observable, action, computed } from 'mobx';
import { formatDateString, parseISO } from '../utils/dateFormat';
import isSameDay from 'date-fns/isSameDay';

export interface EventItem {
  title: string;
  content: string;
  excerpt: string;
  image: {
    alt: string;
    sizes: any;
  };
  id: number;
  eventSlug: string;
  startDate: string;
  endDate: string;
  shortDescription: string;
}
export class EventItemStore {
  static _store: EventItemStore;
  static get store() {
    if (this._store) {
      return this._store;
    }
    this._store = new EventItemStore();
    window['EventItemStore'] = this._store;
    return this._store;
  }

  @observable currentItem: EventItem;
  @observable displayingInModal = false;

  @action toggleDisplayModal() {
    this.displayingInModal = !this.displayingInModal;
  }
  @action displayEventItem(item) {
    this.currentItem = item;
  }
  @action closeModal() {
    if (!this.displayingInModal) {
      this.currentItem = null;
    } else {
      this.displayingInModal = false;
    }
  }
}

export function getDateStringFromEvent(card: EventItem) {
  if (card.startDate === card.endDate) {
    return stripZeros(card.startDate);
  } else if (isSameDay(parseISO(card.startDate), parseISO(card.endDate))) {
    let dateString = formatDateString(card.startDate, 'd MMMM HH:mm');

    if (formatDateString(card.endDate, 'HH:mm') !== '00:00') {
      dateString += ' - ' + formatDateString(card.endDate, 'HH:mm');
    }
    return dateString;
  } else {
    return `${stripZeros(card.startDate)} - ${stripZeros(card.endDate)}`;
  }
}
function stripZeros(dateString: string) {
  let newDateString = formatDateString(dateString, 'd MMMM HH:mm');
  if (newDateString.includes('00:00')) {
    newDateString = formatDateString(dateString, 'd MMMM');
  }
  return newDateString;
}
