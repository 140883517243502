export const $ = window.jQuery;
// https://stackoverflow.com/a/13655739/719380
$(window).load(function() {
  $('.lazyload').each(function() {
    var lazy = $(this);
    var src = lazy.attr('data-src');

    $('<img>')
      .attr('src', src)
      .load(function() {
        lazy.css('background-image', 'url("' + src + '")');
      });
  });
});
