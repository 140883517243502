import { observer } from 'mobx-react';
import React, { useLayoutEffect, useState } from 'react';
import './modals.scss';
import Modal from 'react-modal';
import { useScrollBodyLock } from '../hooks/useScrollBodyLock';
import { ModalStore } from './ModalStore';
import { hot } from 'react-hot-loader';
import { removeHash, detectHashAndDisplayModal } from './hashDetector';
import {
  ModalForm,
  ModalFormContaienr as ModalFormContainer,
} from './ModalForm';
import he from 'he';
const $ = window.jQuery;

import { SwitchTransition, CSSTransition } from 'react-transition-group';

// Modal.setAppElement('#modal');
const modalApp = document.querySelector('#modalsReactApp');
if (modalApp) {
  detectHashAndDisplayModal();

  window.addEventListener(
    'popstate',
    function(event) {
      if (ModalStore.store.displayingModal) {
        ModalStore.store.closeModal();
      }
    },
    false,
  );

  window.addEventListener(
    'hashchange',
    function() {
      detectHashAndDisplayModal();
    },
    false,
  );

  document.querySelectorAll('.ModalLink').forEach((link: HTMLAnchorElement) => {
    link.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();
      const anchorLink = e.currentTarget as HTMLAnchorElement;
      history.pushState(null, null, `#${anchorLink.id}`);
      ModalStore.store.displayModal(anchorLink.dataset.item);
      return false;
    });
  });
}

// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

// const lock = () => {
//   disableBodyScroll(ref.current);
// };
// const unlock = () => {
//   enableBodyScroll(ref.current);
// };
const ModalsApp = observer(function ModalsApp() {
  const open = ModalStore.store.displayingModal;

  const { lock, unlock } = useScrollBodyLock();

  return (
    <Modal
      ariaHideApp={false}
      closeTimeoutMS={200}
      isOpen={open}
      contentLabel="modal"
      className={`Modal`}
      overlayClassName="Overlay"
      onAfterOpen={lock}
      onAfterClose={() => {
        unlock();
        removeHash();
      }}
      onRequestClose={ModalStore.store.closeModal}
    >
      <ModalContent />
    </Modal>
  );
});

const ModalContent = observer(() => {
  const modalContent = ModalStore.store.modalContent;
  const displayingForm = ModalStore.store.displayingForm;

  useLayoutEffect(() => {
    if (modalContent) {
      const isMany = ModalStore.store.modalImages.length > 1;
      try {
        const slideshow = $('.slideshow-small');
        slideshow.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: isMany,
          autoplaySpeed: 4000,
          arrows: isMany,
          dots: false,
          pauseOnHover: false,
        });
      } catch (e) {}
    }
  }, [modalContent]);
  if (!modalContent) {
    return null;
  }
  return (
    <>
      <div className="slideshow-small">
        {ModalStore.store.modalImages.map(image => (
          <img src={image.sizes.card_retina} alt="" key={image.id} />
        ))}
      </div>
      <div className="modalHeader">
        <span></span>
        <button
          type="button"
          className="closeModalIcon"
          data-dismiss="modal"
          aria-label="Close"
          onClick={ModalStore.store.closeModal}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="card-body">
        <SwitchTransition mode={'out-in'}>
          <CSSTransition
            key={displayingForm ? 'displayingForm' : 'noForm'}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            classNames="fade"
          >
            <div>
              {displayingForm && modalContent.selectForm ? (
                <ModalFormContainer />
              ) : (
                <div className="animated">
                  <h3>{he.decode(modalContent.title)}</h3>
                  <div
                    className="modalContent"
                    dangerouslySetInnerHTML={{
                      __html: modalContent.content,
                    }}
                  ></div>
                  {modalContent.selectForm !== 'false' && (
                    <>
                      <button
                        className="primary-button w-100 text-center"
                        onClick={() => {
                          ModalStore.store.setDisplayingForm(true);
                        }}
                      >
                        Boka
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </>
  );
});

export default hot(module)(ModalsApp);
