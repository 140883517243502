import React, { useEffect, useRef, useLayoutEffect } from 'react';
import { ModalStore } from './ModalStore';
import { scrollSmoothToBottom } from './scrollSmoothToBottom';

export const ModalFormContaienr = () => {
  return (
    <div className="animated">
      <button
        className="primary-button small mb-2"
        onClick={() => {
          ModalStore.store.setDisplayingForm(false);
        }}
      >
        <i className="icon ion-md-arrow-back" />
        <span>{'Tillbaka'}</span>
      </button>
      <ModalForm />
    </div>
  );
};
export const ModalForm = () => {
  const ref = useRef<HTMLDivElement>(null);
  const formCode = ModalStore.store.modalContent.selectForm;
  const match = formCode.match(/id="(\d)/);
  const form = document.querySelector(`#form-${match[1]}`);

  useLayoutEffect(() => {
    form.classList.remove('d-none');
    const subject = form.querySelector<HTMLInputElement>(
      'input[name="your-subject"]',
    );
    // subject.disabled = true;
    subject.value = ModalStore.store.contentGroup.title;

    // We add the form to this modal and once were done we put it back in the body
    ref.current.appendChild(form);
    return () => {
      form.classList.add('d-none');
      document.body.appendChild(form);
    };
  }, []);
  useEffect(() => {
    const modal = document.querySelector('.Modal');
    modal.scrollTop = 0;
    const name = form.querySelector<HTMLInputElement>(
      'form input[name="your-name"]',
    );
    name.focus();
  }, []);
  return <div ref={ref}></div>;
};

// Close the form when the submission completes!
const wpcf7Elm = document.querySelector('.wpcf7');
if (wpcf7Elm) {
  wpcf7Elm.addEventListener(
    'wpcf7mailsent',
    function(event) {
      const output = document.querySelector('.wpcf7-response-output');
      output.classList.remove('d-none');

      // Make it look nice
      output.classList.add('alert', 'alert-success');
      setTimeout(() => {
        scrollSmoothToBottom('.Modal');
      }, 300);
    },
    false,
  );
}

document.addEventListener(
  'wpcf7invalid',
  function(event) {
    $('.wpcf7-response-output').addClass('alert alert-danger');
  },
  false,
);
document.addEventListener(
  'wpcf7spam',
  function(event) {
    $('.wpcf7-response-output').addClass('alert alert-warning');
  },
  false,
);
document.addEventListener(
  'wpcf7mailfailed',
  function(event) {
    $('.wpcf7-response-output').addClass('alert alert-warning');
  },
  false,
);
