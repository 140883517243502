import { observer } from 'mobx-react';
import React from 'react';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';
import { removeHash } from '../modals/hashDetector';
import { allEvents } from './allEvents';
import { EventItemStore, getDateStringFromEvent } from './EventItemStore';
import he from 'he';

export const SelectedEvent = observer(function SelectedEventApp() {
  let eventItem = EventItemStore.store.currentItem;
  const mobile = useMediaQuery({ query: '(max-width: 767.98px)' });
  const displayingInModal = EventItemStore.store.displayingInModal;

  // If not mobile we display the first item
  if (!mobile && !eventItem && allEvents.length) {
    eventItem = allEvents[0];
  }
  if (!eventItem) {
    return null;
  }
  // get the image from the selected id

  if (mobile || displayingInModal) {
    return (
      <>
        {displayingInModal && (
          <EventContent
            eventItem={eventItem}
            id={`evenemang_${eventItem.id}`}
          />
        )}
        <Modal
          ariaHideApp={false}
          closeTimeoutMS={200}
          isOpen={!!eventItem}
          contentLabel="modal"
          className={`Modal`}
          overlayClassName="Overlay"
          // onAfterOpen={lock}
          onAfterClose={() => {
            // unlock();
            removeHash();
          }}
          onRequestClose={() => {
            EventItemStore.store.closeModal();
          }}
        >
          <EventContent eventItem={eventItem} isModal />
        </Modal>
      </>
    );
  }
  return (
    <EventContent eventItem={eventItem} id={`evenemang_${eventItem.id}`} />
  );
});

const EventContent = observer(({ eventItem, isModal = false, id = null }) => {
  if (!eventItem) {
    return null;
  }
  return (
    <div
      id={id}
      className={`bg-white shadow d-flex flex-column ${isModal ? 'modal_content' : ''
        }`}
    >
      <div className={`modalHeader ${isModal ? '' : 'd-none'}`}>
        <span></span>
        <button
          type="button"
          className="closeModalIcon"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            EventItemStore.store.closeModal();
          }}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className="card-image-header">
        <img
          src={eventItem.image.sizes.medium}
          srcSet={`${eventItem.image.sizes.medium} 1x, ${eventItem.image.sizes.large} 2x`}
          alt={eventItem.image.alt}
        />
      </div>
      <div className="card-body d-flex flex-column">
        <h3>{he.decode(eventItem.title)}</h3>
        <p className="text-muted text-capitalize">
          {getDateStringFromEvent(eventItem)}
        </p>
        <div
          className="text-muted"
          dangerouslySetInnerHTML={{
            __html: isModal ? eventItem.content : eventItem.shortDescription,
          }}
        ></div>
        {eventItem.content.length > 0 && eventItem.excerpt.length !== eventItem.content.length && !isModal && (
          <button
            className="button mt-2 align-self-end"
            onClick={() => {
              EventItemStore.store.toggleDisplayModal();
            }}
          >
            Visa hela
          </button>
        )}
      </div>
    </div>
  );
});
