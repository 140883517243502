import React, { useState } from 'react';
import {
  EventItem,
  getDateStringFromEvent,
  EventItemStore,
} from './EventItemStore';
import { observer } from 'mobx-react';
import { allEvents } from './allEvents';
import { useMediaQuery } from 'react-responsive';
import { scrollToSelectedEvent } from './scrollToSelectedEvent';
import he from 'he';

const site = window.WPInitialData;
const MAX_VISIBLE = 7;
export const EventList = observer(() => {
  const [page, setPage] = useState(0);
  const displayedEvents = allEvents.slice(0, (page + 1) * MAX_VISIBLE);
  const totalPages = Math.ceil(allEvents.length / MAX_VISIBLE) - 1;
  return (
    <>
      {/* <button
        onClick={() => {
          setPage(p => p - 1);
        }}
      >
        prev
      </button> */}
      <div className="event_list">
        {displayedEvents.map(event => (
          <SingleEvent
            key={event.id}
            card={event}
            active={event.id === EventItemStore.store.currentItem?.id}
            onSelected={selected => {
              EventItemStore.store.displayEventItem(selected);
            }}
          />
        ))}
      </div>
      {page !== totalPages && (
        <button
          className="button show_more_button"
          onClick={() => {
            setPage(p => p + 1);
          }}
        >
          <i className="icon ion-md-arrow-down "></i>
        </button>
      )}
    </>
  );
});

const SingleEvent = ({
  card,
  onSelected,
  active,
}: {
  card: EventItem;
  active: boolean;
  onSelected: (event: EventItem) => void;
}) => {
  const dateString = getDateStringFromEvent(card);
  const mobile = useMediaQuery({ query: '(max-width: 767.98px)' });
  return (
    <div className="single_event_card">
      <div className="image_holder">
        <img src={card.image.sizes.thumbnail} alt={card.image.alt} />
      </div>
      <div className="card_body">
        <div className="d-flex flex-column">
          <h3>{he.decode(card.title)}</h3>

          <div className="flex-fill mb-2 d-block text-muted text-capitalize">
            {dateString}
          </div>
        </div>

        <a
          href={`${site.URL}/aktiviteter-och-evenemang/#evenemang_${card.eventSlug}`}
          className={`button button-small nomove ${active ? 'active' : ''}`}
          onClick={() => {
            if (mobile) {
              return;
            }
            scrollToSelectedEvent();
          }}
        >
          <i className="icon ion-md-arrow-forward "></i>
        </a>
      </div>
    </div>
  );
};
